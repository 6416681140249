import { motion } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import CoinList from 'components/coin-list-2.svg';
import { FormattedMessage } from 'react-intl';

export function CoinsBlock() {
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900' />
        <div className='mb-32 sm:mb-0  flex flex-col items-center justify-center h-full'>
            <div className='px-8 sm:px-0 flex flex-col lg:flex-row items-center justify-center relative w-full'>
                <div className='mt-16 md:mb-16 sm:w-9/12 md:w-7/12 lg:mt-0 lg:mr-20 lg:w-3/12 order-2 lg:order-1'>
                    <motion.h2
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.6 }}
                        viewport={{ once: true }}
                        className='font-title ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                    >
                        <FormattedMessage defaultMessage="Accept USDT, Ethereum, {link}" id="eoTSVw" values={{
                            link: <a href="https://docs.repaya.io/docs/supported-coins" rel="noreferrer noopener" target="_blank" className=' no-underline text-rose-400 hover:text-rose-100 transition-colors duration-200 whitespace-nowrap'>
                                <FormattedMessage defaultMessage="and more" id="Wx9ulF" />
                            </a>
                        }} />
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .8 }}
                        viewport={{ once: true }}
                        className='mt-8 text-lg'>
                        <FormattedMessage defaultMessage="We offer support for a selected range of reliable stablecoins and tokens on the Ethereum blockchain. We prioritize simplicity and trustworthiness in our selection, ensuring a streamlined experience for our users." id="3dAlGy" />
                    </motion.p>
                </div>
                <motion.div
                    initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                    viewport={{ once: true }}
                    className="w-full lg:w-5/12 xl:w-4/12 2xl:w-3/12 order-1 lg:order-2">
                    <CoinList className="w-full" />
                </motion.div>
            </div>
        </div>
    </FixedSlide>;
}
