import { delay, noRace, wait } from '@repaya/commons/async2'
import cn from 'classnames'
import Panel, { PanelPadding, usePanel } from '../ui/Panel'
import { Select } from '../ui/Select'
import { useAnimationControls, motion } from 'framer-motion'
import { CoinOption } from './helpers'
import IntegrationInfo from './IntegrationInfo'
import { Coin } from 'coin/types'
import TotalsDummy from './TotalsDummy'
import { Button } from 'ui/Button'
import ClickEffect from 'ui/ClickEffect'
import Metamask from 'ui/metamask.svg'
import Coinbase from 'ui/coinbase.svg'
import Rainbow from 'ui/rainbow.svg'
import Ledger from 'ui/ledger.svg'
import WalletConnect from 'ui/wallet-connect.svg'
import OtherWallet from 'ui/other-wallet.svg'
import { useAsync, useEvent, useInterval } from 'common/hooks'
import { useRef, useState } from 'react'
import CompletePayment from './CompletePayment'
import AvatarAddress from 'ui/AvatarAddress'
import { FormattedMessage, useIntl } from 'react-intl'

interface Props {
}

const coins: Coin[] = [{"code":"USDT","name":"Tether USD","data":{"group":"usd","address":"0xdac17f958d2ee523a2206206994597c13d831ec7","decimals":6},"type":"token","isAvailable":true},{"code":"DAI","name":"Dai Stablecoin","data":{"group":"usd","address":"0x6b175474e89094c44da98b954eedeac495271d0f","decimals":18},"type":"token","isAvailable":true},{"code":"USDC","name":"USD Coin","data":{"group":"usd","address":"0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48","decimals":6},"type":"token","isAvailable":true},{"code":"BUSD","name":"Binance USD","data":{"group":"usd","address":"0x4fabb145d64652a948d72533023f6e7a623c7c53","decimals":18},"type":"token","isAvailable":true},{"code":"USDP","name":"Pax Dollar","data":{"group":"usd","address":"0x8e870d67f660d95d5be530380d0ec0bd388289e1","decimals":18},"type":"token","isAvailable":true}]

export default function PaymentFormDummy({
}: Props) {
    const intl = useIntl()
    const [coin, setCoin] = useState(coins[0])
    const handleChangeCoin = useEvent(code => setCoin(coins.find(c => c.code === code)!))

    const address = '0x0000000000000000000000000000000000000000'

    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)

    const formControls = useAnimationControls()
    const mainButtonControlls = useAnimationControls()
    const iconControlls = useAnimationControls()
    const [mainButtonClickId, setMainButtonClickId] = useState(0)
    const [firstWalletClickedId, setFirstWalletClickedId] = useState(0)
    const iconBounceControlls = useAnimationControls()
    const successControlls = useAnimationControls()
    const isAnimatedRef = useRef(false)

    const [onStartDemo, isAnimating] = useAsync(async function* () {
        isAnimatedRef.current = true
        mainButtonControlls.start('click')
        await Promise.all([
            formControls.start('open'),
            iconControlls.start('open')
        ])

        yield* delay(100)
        setFirstWalletClickedId(prev => prev + 1)
        await iconBounceControlls.start({ scale: [1, 0.8, 1.06, 0.98, 1], transition: { stiffness: 50, duration: .5 } })
        setLoading(true)
        yield* delay(350)
        await Promise.all([
            iconControlls.start('close'),
            formControls.start('close')
        ])
        yield* delay(800)
        await successControlls.start('open')
        setSuccess(true)
        setLoading(false)
        yield* delay(3800)
        await successControlls.start('close')
        setSuccess(false)
    })

    useInterval(() => {
        if (isAnimatedRef.current) return
        setMainButtonClickId(prev => prev + 1)
    }, 7000)

    const iconsStart = 13
    const iconsRow = 70

    return (
        <div className='w-full px-4 sm:px-16 md:px-0 sm:w-9/12 md:w-7/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 2xl:pr-8 flex flex-row items-center justify-center relative'>
            <motion.div
                className='relative w-full rounded-2xl z-20 '
                animate={formControls}
                variants={{
                    open: { x: -100, transition: { delay: .6, duration: .4, stiffness: 2 } },
                    close: { x: 0, transition: { duration: .4, delay: .8, stiffness: 2 } }
                }}
            >
                <div className={cn('w-full', {
                    'hidden': isSuccess
                })}>
                    <motion.div
                        className='absolute opacity-0 right-0 -z-10'
                        animate={iconControlls}
                        custom={0}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        style={{ top: iconsStart }}
                    >
                        <ClickEffect clickedId={firstWalletClickedId} innerClassName='rounded-xl' >
                            <motion.div className="w-14 h-14 rounded-2xl overflow-hidden" animate={iconBounceControlls}>
                                <Metamask className="w-14 h-14" />
                            </motion.div>
                        </ClickEffect>
                    </motion.div>
                    <motion.div
                        className='w-14 h-14 rounded-2xl overflow-hidden absolute opacity-0 right-0 -z-10'
                        animate={iconControlls}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        custom={1}
                        style={{ top: iconsStart + iconsRow * 1 }}
                    >
                        <Coinbase className="w-full h-full" />
                    </motion.div>
                    <motion.div
                        className='w-14 h-14 rounded-2xl overflow-hidden absolute opacity-0 right-0 -z-10'
                        animate={iconControlls}
                        custom={2}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        style={{ top: iconsStart + iconsRow * 2 }}
                    >
                        <Rainbow className="w-full h-full" />
                    </motion.div>
                    <motion.div
                        className='w-14 h-14 rounded-2xl overflow-hidden opacity-0 absolute right-0 -z-10'
                        animate={iconControlls}
                        custom={3}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        style={{ top: iconsStart + iconsRow * 3 }}
                    >
                        <Ledger className="w-full h-full" />
                    </motion.div>
                    <motion.div
                        className='w-14 h-14 rounded-2xl overflow-hidden opacity-0 absolute right-0 -z-10'
                        animate={iconControlls}
                        custom={4}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        style={{ top: iconsStart + iconsRow * 4 }}
                    >
                        <WalletConnect className="w-full h-full" />
                    </motion.div>
                    <motion.div
                        className='w-14 h-14 rounded-2xl overflow-hidden opacity-0 absolute right-0 -z-10'
                        animate={iconControlls}
                        custom={5}
                        variants={{
                            open: (i: number) => ({ x: 74, opacity: 1, transition: { delay: i * .1 + .8 } }),
                            close: (i: number) => ({ x: 0, opacity: 0, transition: { delay: i * .1, stiffness: 200 } })
                        }}
                        style={{ top: iconsStart + iconsRow * 5 }}
                    >
                        <OtherWallet className="w-full h-full" />
                    </motion.div>
                </div>
                <div className={cn('prose max-w-none prose-neutral dark:prose-invert relative rounded-2xl z-10 dark:bg-neutral-900  border  hover:border-rose-400 hover:border-opacity-30 transition-colors duration-500', {
                    'border-opacity-20 border-rose-400': isAnimating,
                    'border-opacity-10 border-neutral-400': !isAnimating,
                })}>
                    {isSuccess && (
                        <motion.div
                            // initial={{
                            //     opacity: 0
                            // }}
                            className='absolute inset-0 flex flex-row items-center justify-center'
                            variants={{
                                open: { opacity: [0, 1], transition: { duration: .3 } },
                                close: { opacity: 0, transition: { duration: .3 } },
                            }}
                            animate={successControlls}
                        >
                            <CompletePayment />
                        </motion.div>
                    )}
                    <PanelPadding className={cn({
                        'opacity-0': isSuccess
                    })}>
                        <IntegrationInfo
                            storeDomain={'https://example.com'}
                            storeName={intl.formatMessage({ defaultMessage: "Online store", id: 'Rr2Mwi'})}
                            productId={'5396'}
                            productName={intl.formatMessage({ defaultMessage: 'Awesome product', id: 'Ek0wTm'})}
                            price={'15.0'}
                            coin={coin}
                        />

                        <TotalsDummy
                            className='my-4 mt-8'
                            coin={coin}
                            showPendingBuffer
                            amount={'15.0'}
                            paidAmount={isLoading ? '0.0' : undefined}
                            hideSum={true}
                        />

                        <div className='flex flex-row items-stretch mb-4 group-row'>
                            <div className={cn('mr-4 min-w-min', {
                                'grow': true,
                            })}>
                                <Select
                                    className='w-full h-full'
                                    value={coin?.code ?? null}
                                    placement='bottom-end'
                                    onChange={handleChangeCoin}
                                    placeholder={<div style={{ lineHeight: '32px' }}>
                                        <FormattedMessage defaultMessage="Select coin" id="EXhrde" />
                                    </div>}
                                    options={coins.map(coin => ({
                                        value: coin.code, label: <CoinOption coin={coin} />
                                    }))}
                                />
                            </div>

                            <ClickEffect clickedId={mainButtonClickId} innerClassName='rounded-xl'>
                                <motion.div
                                    variants={{
                                        click: { scale: [1, 0.8, 1.06, 0.98, 1], transition: { stiffness: 50, duration: .5 } },
                                        bounce: {
                                            rotate: [0, 6, 0, -6, 0, 3, 0, -3, 0],
                                            y: [0, 10, 0, 10, 0, 10, 0, 10, 0],
                                            transition: {
                                                duration: 1.2
                                            }
                                        }
                                    }}
                                    animate={mainButtonControlls}
                                >
                                    <Button
                                        onClick={onStartDemo}
                                        isLoading={isLoading}
                                        isDisabled={false}
                                        className={cn("font-normal px-8 py-5 rounded-2xl", {})}
                                        color="select"
                                        size="large-padding"
                                    >
                                        <span className='px-0'>
                                            <FormattedMessage defaultMessage="Demo checkout" id="cZvzSB" />
                                        </span>
                                    </Button>
                                </motion.div>
                            </ClickEffect>
                        </div>
                        <div className={cn('flex flex-row items-center px-4', {
                            'justify-end': true,
                        })}>
                            <div className='flex flex-row justify-start items-center'>
                                <div className='mr-4 opacity-40'>
                                    <FormattedMessage defaultMessage="Paying with" description="Paying with {address}" id="+BZNDH" />
                                </div>
                                <AvatarAddress address={address} />
                            </div>
                        </div>
                    </PanelPadding>
                </div>
            </motion.div>
        </div>
    )
}