import { motion } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import WalletList from 'components/wallet-list.svg';
import { FormattedMessage } from 'react-intl';

export function WalletsBlock() {
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900' />
        <div className='mb-32 sm:mb-0 flex flex-col items-center justify-center h-full'>
            <div className='px-8 md:px-0 flex flex-col lg:flex-row items-center justify-center relative w-full'>
                <motion.div
                    initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                    viewport={{ once: true }}
                    className="w-full lg:w-5/12 xl:w-4/12 2xl:w-3/12 mb-16 md:mb-16 lg:mb-0">
                    <WalletList className="w-full" />
                </motion.div>
                <div className='sm:w-9/12 md:w-7/12 lg:mb-0 lg:ml-20 lg:w-3/12'>
                    <motion.h2
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .6 }}
                        viewport={{ once: true }}
                        className='font-title ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                    >
                        <FormattedMessage
                            defaultMessage="Receive payments {link} into your wallet" id="phB0O2"
                            values={{
                                link: <a href="https://docs.repaya.io/" rel="noreferrer noopener" target="_blank" className='no-underline text-rose-400 hover:text-rose-100 transition-colors duration-200'>
                                    <FormattedMessage defaultMessage="directly" id="dNFQ4u" />
                                </a>
                            }} />

                    </motion.h2>
                    <motion.div
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .8 }}
                        viewport={{ once: true }}
                        className='mt-8 text-lg'>
                        <p className="mt-8">
                            <FormattedMessage defaultMessage="All the transactions are completed without the involvement from any intermediaries or costly smart-contract executions, making the process more efficient and cost-effective for all parties involved." id="3ciWu4" />
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    </FixedSlide>;
}
