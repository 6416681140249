import cn from 'classnames'
import { format, isSameDay, isSameYear } from 'date-fns'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import MultiCoinIcon from '../coin/MultiCoinIcon'
import { Coin } from '../coin/types'
import Address from '../ui/Address'
import { Payment } from './types'


interface Props {
    payment: Payment
    showProductColumn?: boolean
    coin: Coin | null
    dateStr: string
}

const na = <span className='opacity-10'>N / A</span>

function Td({ children, className }: { children: ReactNode, className?: string }) {
    return (
        <td className={cn('p-3 px-3', className)}>
            {children}
        </td>
    )
}

function Status({ status }: { status: string }) {
    const isCompleted = ['completed', 'paid'].includes(status)
    const isPending = ['pending'].includes(status)
    const isFailed = ['failed'].includes(status)
    const isCanceled = ['canceled'].includes(status)
    return (
        <span className={cn('p-2 px-3 rounded-2xl text-sm sm:text-normal', {
            'dark:bg-green-400  bg-green-500 bg-opacity-20 dark:bg-opacity-40': isCompleted,
            'animate-pulse dark:bg-neutral-700 dark:text-neutral-400 bg-neutral-200 text-neutral-500': isPending,
            'dark:bg-neutral-800 dark:text-neutral-400 bg-neutral-200 text-neutral-500': isCanceled,
            'dark:bg-red-500 bg-red-500 bg-opacity-30 dark:bg-opacity-60': isFailed,
        })} style={{ lineHeight: 1 }}>
            {isCompleted && <FormattedMessage defaultMessage="Completed" id="95stPq" />}
            {isPending && <FormattedMessage defaultMessage="Pending" id="eKEL/g" />}
            {isFailed && <FormattedMessage defaultMessage="Failed" id="vXCeIi" />}
            {isCanceled && <FormattedMessage defaultMessage="Canceled" id="PFtMy9" />}
        </span>
    )
}

export default function PaymentRowDummy({
    payment,
    showProductColumn,
    coin,
    dateStr
}: Props) {
    return (
        <tr className='dark:even:bg-neutral-800 dark:even:bg-opacity-20 hover:dark:bg-neutral-800 hover:dark:bg-opacity-40 even:bg-neutral-100 even:bg-opacity-50 hover:bg-neutral-100 hover:bg-opacity-100'>
            {/* <Td>{payment.id}</Td> */}
            <Td className='hidden sm:table-cell whitespace-nowrap w-1/4'>
                <span>
                    {dateStr}
                </span>
            </Td>
            <Td className='w-1/4'>
                <div className=''>
                    <Address isResponsive showExplorerLink={payment.sender.replace(/[0x]/g, '').length > 0} first={3} last={5} address={payment.sender} />
                </div>
                {payment.customerId && (
                    <div className='hidden sm:block mt-1'>
                        <span className="opacity-40"><FormattedMessage defaultMessage="Customer ID:" id="Q6I/MO" /></span> <span className='mr-4'>{payment.customerId}</span>
                    </div>
                )}
            </Td>
            {showProductColumn && (
                <Td className=' w-1/4 overflow-hidden max-w-0 hidden xs:table-cell'>
                    {(!payment.productId && !payment.productName) && na}
                    {(payment.productId || payment.productName) && (
                        <div className='overflow-hidden w-full max-w-full '>
                            {payment.productName && (
                                <div className="overflow-hidden text-ellipsis whitespace-nowrap" style={{ maxWidth: '100%', minWidth: 0 }}>{payment.productName}</div>
                            )}
                            {payment.productId && (
                                <div className='hidden sm:block mt-1'>
                                    <span className="opacity-40"><FormattedMessage defaultMessage="ID:" id="tmcdrp" /></span> <span>{payment.productId}</span>
                                </div>
                            )}
                        </div>
                    )}
                </Td>
            )}
            <Td className="whitespace-nowrap w-1/4">
                <div className='flex flex-row items-center justify-start'>
                    <div>
                        {payment.amount} <span className='hidden sm:inline'>/ {payment.paidAmount}</span>
                    </div>
                    <div className='ml-3 mr-2' style={{ top: -2 }}>
                        <MultiCoinIcon coin={coin} className="inline-block align-middle whitespace-nowrap mr-0" shift={10} size={22} />
                    </div>
                    <div className='hidden lg:block opacity-60'>{coin?.type === 'multi' ? coin.name : coin?.code}</div>
                </div>
            </Td>
            <Td className='w-0'><Status status={payment.status} /></Td>
        </tr>
    )
}