import cn from 'classnames'
import { useTimeout } from 'common/hooks'
import { withPersistentStore } from 'common/state'
import { storage } from 'common/storage'
import { useCallback, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from 'ui/Button'
import create from 'zustand'

const useBaseCookieNoticeState = create<boolean>(() => true)

const useCookieNoticeState = withPersistentStore(
    storage,
    'cookie-notice.v1',
    useBaseCookieNoticeState
)

export interface CookieNoticeProps {

}

export function CookieNotice({

}: CookieNoticeProps) {
    const [firstLoad, setFirstLoad] = useState(true)
    const showCookieNotice = useCookieNoticeState()

    const handleClick = useCallback(() => useCookieNoticeState.setState(false), [])

    useTimeout(() => setFirstLoad(false), 2000, [])

    return (
        <div className={cn(
            'fixed z-50 h-16 px-4 sm:px-16 bg-neutral-900 bg-opacity-90 sm:bg-opacity-60 backdrop-blur-sm w-full bottom-0 left-0 right-0 flex flex-row items-center justify-start',
            'border border-x-0 border-neutral-800 border-opacity-50',
            'text-neutral-700 transition-opacity duration-300',
            {
                'opacity-0': !showCookieNotice,
                'opacity-5': showCookieNotice && firstLoad
            }
        )}>
            <span className='mr-4'>
                <FormattedMessage
                    defaultMessage="Our website uses cookies to ensure you get the best user experience."
                    id="ue1PPW"
                    values={{
                        link:
                            <a rel="noopener noreferrer" target="_blank" href="https://docs.repaya.io/legal/privacy-policy" className='text-neutral-600 hover:text-neutral-300 transition-colors duration-200'>
                                <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
                            </a>
                    }}
                />
            </span>
            <Button
                size="small"
                onClick={handleClick}
            >
                <FormattedMessage defaultMessage="Got it" id="NYTGIb" />
            </Button>
        </div>
    )
}