import LandingLayout from "components/LandingLayout"
import Landing from "landing/Landing"

interface Props {

}

export default function LandingPage({

}: Props) {
    return (
        <Landing />
    )
}

LandingPage.getLayout = LandingLayout
