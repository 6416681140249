import cn from 'classnames'
import Decimal from 'decimal.js'
import { useMemo } from 'react'
import Spinner from '../ui/spinner.svg'
import { Coin } from '../coin/types'
import Address from 'ui/Address'
import { FormattedMessage } from 'react-intl'

interface Props {
    coin: Coin
    amount?: string
    hideSum?: boolean
    paidAmount?: string
    showPendingBuffer?: boolean
    className?: string
    receiver?: string | null
    customerId?: string | null
}

export default function TotalsDummy({
    coin,
    amount,
    hideSum,
    className,
    paidAmount,
    showPendingBuffer,
    receiver,
    customerId,
}: Props) {
    const coinPrice = coin && coin.data.group === 'usd' ? '1' : null

    const [total, cost] = useMemo(() => {
        if (amount == null || amount == '') return [null, null]

        try {
            const cost = coinPrice ? new Decimal(coinPrice).mul(amount).toFixed(2) : null
            const total = new Decimal(amount).toFixed()

            return [total, cost]
        } catch (error) {
            return [null, null]
        }
    }, [amount, coinPrice])

    return (
        <div className={cn('px-4', className)}>
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <FormattedMessage defaultMessage="Total" id="MJ2jZQ" />
                </div>
                {(amount != null && amount != '') && (
                    <>
                        <div className='grow mx-4'>
                            <div className='bg-neutral-200 dark:bg-neutral-800' style={{
                                height: 1,
                            }} />
                        </div>
                        {cost != null && (
                            <div className={cn('font-mono', {
                                'mr-4 opacity-40': !hideSum
                            })}>
                                <span className='opacity-60'>$</span>
                                {cost}
                            </div>
                        )}
                        {(!hideSum && total != null) && (
                            <div className='font-mono'>
                                {total} <span className="opacity-50">{coin.code}</span>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className='flex flex-row justify-between items-center'>
                <div>
                    <FormattedMessage defaultMessage="Network fee" id="UPGW9b" />
                </div>
                <div className='grow mx-4'>
                    <div className='bg-neutral-200 dark:bg-neutral-800' style={{
                        height: 1,
                    }} />
                </div>
                <div className='font-mono'>
                    <span className="opacity-30">≈</span><span className='opacity-60'>$</span>0.62
                </div>
            </div>
            {!!paidAmount && (
                <div className='flex flex-row justify-between items-center'>
                    <div>
                        <FormattedMessage defaultMessage="Paid" id="u/vOPu" />
                    </div>
                    {(amount != null && amount != '') && (
                        <>
                            <div className='grow mx-4'>
                                <div className='bg-neutral-200 dark:bg-neutral-800' style={{
                                    height: 1,
                                }} />
                            </div>
                            <div className='font-mono flex flex-row items-center'>
                                <div className=' mr-2'>
                                    <div>
                                        <Spinner className='w-4 animate-spin dark:fill-rose-200 fill-neutral-500' />
                                    </div>
                                </div>
                                <div className='mr-2'>
                                    {new Decimal(paidAmount).toFixed()}
                                </div>
                                <div className="opacity-50">{coin.code}</div>
                            </div>
                        </>
                    )}
                </div>
            )}
            {receiver && (
                <div className='flex flex-row justify-between items-center mt-1'>
                    <div style={{ lineHeight: 1 }}>
                        <div>
                            <FormattedMessage defaultMessage="Receiving address" id="kHZfOt" />
                        </div>
                    </div>
                    {(amount != null && amount != '') && (
                        <>
                            <div className='grow mx-4'>
                                <div className='bg-neutral-200 dark:bg-neutral-800' style={{
                                    height: 1,
                                }} />
                            </div>
                            <div className='flex flex-row items-center'>
                                <Address first={5} last={5} address={receiver} />
                            </div>
                        </>
                    )}
                </div>
            )}
            {customerId && (
                <div className='flex flex-row justify-between items-center'>
                    <div className='opacity-30'>
                        <FormattedMessage defaultMessage="Customer ID" id="tyssDm" />
                    </div>
                    <div className='grow mx-4'>
                        <div className='bg-neutral-200 dark:bg-neutral-800' style={{
                            height: 1,
                        }} />
                    </div>
                    <div className='font-mono opacity-30'>
                        {customerId}
                    </div>
                </div>
            )}
            {(!paidAmount && showPendingBuffer) && (
                <div className='flex flex-row justify-between items-center'>
                    <div className='opacity-30'>
                        &nbsp;
                    </div>
                    <div className='font-mono opacity-30'>
                        &nbsp;
                    </div>
                </div>
            )}
        </div>
    )
}