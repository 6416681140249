import cn from 'classnames'
import { ReactNode } from 'react'
import Footer from './Footer'
import LandingHeader from './LandingHeader'

interface Props {
    children?: ReactNode
}

export default function LandingLayout({
    children
}: Props) {
    return (
        <>
            <LandingHeader />
            {children}
        </>
    )
}