import cn from 'classnames'
import { PanelPadding } from '../ui/Panel'
import { Select } from '../ui/Select'
import { motion } from 'framer-motion'
import { CoinOption } from './helpers'
import IntegrationInfo from './IntegrationInfo'
import { Coin } from 'coin/types'
import TotalsDummy from './TotalsDummy'
import { Button } from 'ui/Button'
import AvatarAddress from 'ui/AvatarAddress'
import PaymentRequestInfo from './PaymentRequestInfo'
import NumberInput from 'ui/NumberInput'
import { MotionProps } from 'framer-motion'
import { HTMLProps } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export type Props = {
    type: 'invoice' | 'donation' | 'store' | 'subscription' | 'deposit'
} & Omit<MotionProps, 'ref'> & Omit<HTMLProps<HTMLDivElement>, 'ref'>

export default function PaymentFormDummyTypes({
    type,
    ...rest
}: Props) {
    const intl = useIntl()
    const coin: Coin = {
        code: 'USDC',
        name: 'USD Coin',
        type: 'token',
        isAvailable: true,
        data: {
            group: 'usd',
            decimals: 6,
            address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        }
    }

    const address = '0x0000000000000000000000000000000000000000'

    return (
        // <div className='w-full flex flex-row items-center justify-center relative'>
        <motion.div
            initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .4 }}
            viewport={{ once: true }}
            className={cn('relative select-none origin-top lg:origin-top-left 2xl:pr-8 box-border  pb-8 px-12 lg:px-0 lg:pb-0 sm:pl-8 xl:pl-12 scale-90 sm:scale-80 rounded-2xl z-20 w-full', rest.className ?? '')}
            {...rest}
        >
            <div className='prose pointer-events-none max-w-none prose-neutral dark:prose-invert relative rounded-2xl z-10 dark:bg-neutral-900 border border-rose-400 border-opacity-30'>
                <PanelPadding className={cn({})}>
                    {type === 'invoice' && (
                        <PaymentRequestInfo
                            title={intl.formatMessage({ defaultMessage: "Invoice", id: 'Ecfndm'})}
                            description={intl.formatMessage({ defaultMessage: `\
We are pleased to inform you that the project we have been working on has been successfully completed. In accordance with our agreement, please find attached the final invoice for the total amount of $1500.

Thank you for your business.
Here is your invoice for the project.\
                    `, id: 'bQPerb'})}
                        />
                    )}

                    {type === 'donation' && (
                        <PaymentRequestInfo
                            title={intl.formatMessage({ defaultMessage: "Donate", id: '2IFGap'})}
                            description={intl.formatMessage({ defaultMessage: `
Show your support by making a donation. Your contribution will help us continue to develop and improve our platform, bringing us one step closer to achieving our goals. 
`, id: 'rVnRqS'})}
                        />
                    )}

                    {type === 'store' && (
                        <IntegrationInfo
                            storeDomain={'https://example.com'}
                            storeName={intl.formatMessage({ defaultMessage: "Store", id: 'RTOlSm'})}
                            productId={'5396'}
                            productName={intl.formatMessage({ defaultMessage: "Awesome product", id: 'Ek0wTm'})}
                            price={'15.0'}
                            coin={coin}
                        />
                    )}

                    {type === 'subscription' && (
                        <IntegrationInfo
                            storeDomain={'https://example.com'}
                            storeName={intl.formatMessage({ defaultMessage: "Service", id: 'n7yYXG'})}
                            productId={'subscription_monthly'}
                            productName={intl.formatMessage({ defaultMessage: "3 Month Subscription", id: 'vLOI+o'})}
                            price={'15.0'}
                            coin={coin}
                        />
                    )}

                    {type === 'deposit' && (
                        <PaymentRequestInfo
                            title={intl.formatMessage({ defaultMessage: "Deposit", id: 'dIgBOz'})}
                            description={intl.formatMessage({ defaultMessage: "Top up your account", id: 'm1t1hf'})}
                        />
                    )}


                    {!['donation', 'deposit'].includes(type) && (
                        <TotalsDummy
                            className='my-4 mt-8'
                            coin={coin}
                            amount={type === 'invoice' ? '1500' : '15.0'}
                            hideSum={true}
                            receiver={type === 'invoice' ? '0x0000000000000000000000000000000000000000' : null}
                            customerId={'61492'}
                        />
                    )}

                    {['donation', 'deposit'].includes(type) && (
                        <div className='opacity-40 pl-4 mb-2'>
                            <FormattedMessage defaultMessage="Enter the amount you'd like to pay" id="o1UE3F" />
                        </div>
                    )}

                    <div className='flex flex-row items-stretch mb-4 group-row'>
                        <div className={cn('mr-4 min-w-min', {
                            'w-5/12': ['donation', 'deposit'].includes(type),
                            'grow': !['donation', 'deposit'].includes(type),
                        })}>
                            <Select
                                className='w-full h-full'
                                value={coin?.code ?? null}
                                placement='bottom-end'
                                placeholder={<div style={{ lineHeight: '32px' }}>
                                    <FormattedMessage defaultMessage="Select coin" id="EXhrde" />
                                </div>}
                                options={coin ? [coin].map(coin => ({
                                    value: coin.code, label: <CoinOption coin={coin} />
                                })) : []}
                            />
                        </div>

                        {['donation', 'deposit'].includes(type) && (
                            <NumberInput
                                value={'5.0'}
                                name="amount"
                                className='w-7/12'
                                placeholder={intl.formatMessage({ defaultMessage: "Amount", id: '/0TOL5'})}
                                min={0}
                                max={1e18}
                            />
                        )}

                        {!['donation', 'deposit'].includes(type) && (
                            <Button
                                isDisabled={false}
                                className={cn(" pointer-events-none font-title font-extrabold px-8 py-5 rounded-2xl", {})}
                                color="primary"
                                size="large"
                            >
                                {type !== 'store' && <FormattedMessage defaultMessage="Pay Now" id="Ss0sWu" />}
                                {type === 'store' && <FormattedMessage defaultMessage="Checkout" id="BJ2TKX" />}
                            </Button>
                        )}
                    </div>

                    {['donation', 'deposit'].includes(type) && (
                        <TotalsDummy
                            className='my-4 mt-8'
                            coin={coin}
                            amount={'5.0'}
                            hideSum={true}
                            receiver={type === 'donation' ? '0x0000000000000000000000000000000000000000' : null}
                            customerId={'61492'}
                        />
                    )}
                    <div className={cn('flex flex-row items-center px-4', {
                        'justify-between': ['donation', 'deposit'].includes(type),
                        'justify-end': !['donation', 'deposit'].includes(type),
                    })}>
                        <div className='flex flex-row justify-start items-center'>
                            <div className='mr-4 opacity-40'>
                                <FormattedMessage defaultMessage="Paying with" description="Paying with {address}" id="+BZNDH" />
                            </div>
                            <AvatarAddress address={address} />
                        </div>

                        {['donation', 'deposit'].includes(type) && (
                            <Button
                                isDisabled={false}
                                className={cn(" pointer-events-none font-title font-extrabold px-8 py-5 rounded-2xl", {})}
                                color="primary"
                                size="large"
                            >
                                <FormattedMessage defaultMessage="Pay Now" id="Ss0sWu" />
                            </Button>
                        )}
                    </div>

                </PanelPadding>
            </div>
        </motion.div>
        // </div>
    )
}