import { useAsync } from 'common/hooks';
import { useRef, useState } from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import { default as PaymentFormDummyTypes, Props as PaymentFormDummyTypesProps } from 'payment-form/PaymentFormDummyTypes';
import { delay, forever } from '@repaya/commons/async2';
import { FormattedMessage } from 'react-intl';
import { DemoTypeSelect } from "./DemoTypeSelect";

export function SecondDemoType(props: {}) {
    const secondDemoTypeRef = useRef<PaymentFormDummyTypesProps['type']>('invoice');
    const [secondDemoType, setSecondDemoType] = useState(secondDemoTypeRef.current);
    const secondDemoControlls = useAnimationControls();
    const [handleSecondDemoTypeSelect] = useAsync(async function* (type: PaymentFormDummyTypesProps['type']) {
        await secondDemoControlls.start('fadeOut');
        secondDemoTypeRef.current = type;
        setSecondDemoType(type);
        yield* delay(80);
        await secondDemoControlls.start('fadeIn');
    });

    const [onStartSpin] = useAsync(async function* () {
        const types: PaymentFormDummyTypesProps['type'][] = ['invoice', 'donation', 'store', 'subscription', 'deposit'];
        let prevIndex = types.indexOf(secondDemoTypeRef.current);

        yield* forever(async function* () {
            yield* delay(4000);
            const index = types.indexOf(secondDemoTypeRef.current);
            if (index === prevIndex) {
                const nextIndex = (prevIndex + 1) % types.length;
                const next = types[nextIndex]!;
                prevIndex = nextIndex;
                handleSecondDemoTypeSelect(next);
            } else {
                prevIndex = index;
                yield* delay(3000);
            }
        });
    });
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900' />
        <div className='mb-32 sm:mb-0 flex flex-col sm:items-center justify-center h-full'>
            <div className='flex flex-col lg:flex-row items-center lg:items-stretch justify-center relative w-full'>
                <div className='flex flex-col lg:flex-row w-full sm:w-10/12 md:w-8/12 lg:w-6/12 xl:w-5/12 2xl:w-4/12'>
                    <motion.div
                        onViewportEnter={onStartSpin}
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                        viewport={{ once: true }}
                        className='py-6 md:mr-6 text-right'
                    >
                        <DemoTypeSelect onSecondDemoTypeSelect={handleSecondDemoTypeSelect} secondDemoType={secondDemoType} />
                    </motion.div>
                    <PaymentFormDummyTypes
                        animate={secondDemoControlls}
                        // style={{ minHeight: '500px' }}
                        type={secondDemoType}
                        variants={{
                            fadeIn: { opacity: 1, transition: { duration: .2 } },
                            fadeOut: { opacity: 0, transition: { duration: .2 } },
                        }} />
                </div>
                <div className='ml-8  px-8 sm:px-0 sm:w-9/12 md:w-7/12 lg:w-4/12 xl:w-3/12 lg:py-6'>
                    <motion.h2
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .6 }}
                        viewport={{ once: true }}
                        className='font-title ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                    >
                        <FormattedMessage defaultMessage="Crypto {link} for the best user-experience" id="YE9787" values={{
                            link: <a href="https://docs.repaya.io/docs/guides" rel="noreferrer noopener" target="_blank" className=' no-underline text-rose-400 hover:text-rose-100 transition-colors duration-200'>
                                <FormattedMessage defaultMessage="payment flows" id="uq/XWk" />
                            </a>
                        }} />
                    </motion.h2>
                    <motion.p
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .8 }}
                        viewport={{ once: true }}
                        className='mt-8 text-lg'>
                        <FormattedMessage defaultMessage={`
With our diverse range of payment options, you can give your customers the flexibility they need to make purchases in the way that is most convenient for them.
                                        `} id="7IDQlL" />
                        <br />
                        <br />
                        <FormattedMessage defaultMessage={`
Start accepting a variety of payment types today.
                                        `} id="tNEUTK" />
                    </motion.p>
                </div>
            </div>
        </div>
    </FixedSlide>;
}
