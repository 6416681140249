import { motion } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import TransactionsDummy from 'history/TransactionsDummy';
import { FormattedMessage } from 'react-intl';

export function TransactionsBlock() {
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900' />
        <div className='mb-32 sm:mb-0 flex flex-col items-center justify-center h-full'>
            <div className='order-2 sm:order-1 px-8 md:px-0 flex flex-col sm:items-center justify-center relative w-full'>
                <motion.h2
                    initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .6 }}
                    viewport={{ once: true }}
                    className='sm:w-9/12 md:w-7/12 lg:mb-0 lg:w-6/12 font-title lg:text-center ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                >
                    <FormattedMessage defaultMessage="Track your payments in real-time" id="K3W5UK" />
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .8 }}
                    viewport={{ once: true }}
                    className='mt-8 sm:w-9/12 md:w-7/12 lg:mb-0 lg:w-5/12 xl:w-4/12 2xl:w-2/12 lg:text-center text-lg'>
                    <FormattedMessage defaultMessage="Stay up-to-date on the status of your payments at all times with our user-friendly interface." id="GTuuIP" />
                </motion.p>

            </div>
            <motion.div
                className='order-1 sm:order-2 w-full px-8 md:px-0 md:w-9/12 xl:w-7/12 2xl:w-6/12 mb-16 sm:mb-0 sm:mt-16'
                initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                viewport={{ once: true }}
            >
                <TransactionsDummy />
            </motion.div>
        </div>
    </FixedSlide>;
}
