import cn from 'classnames'
import { ReactNode } from 'react'

interface Props {
    className?: string
    children?: ReactNode
}

export default function FixedSlide({
    className,
    children
}: Props) {

    return (
        <>
            <div className=' sm:snap-start'>
                <div
                    className={cn('z-10 sm:h-screen relative min-h-max w-full ', className)}
                    style={{ paddingBottom: 74 }}
                >
                    {children}
                </div>
            </div>
        </>
    )
}