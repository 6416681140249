import { motion } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import { Button } from 'ui/Button';
import { FormattedMessage } from 'react-intl';

export function ActionBlock() {
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900 sm:hidden' />
        <div className=' mb-32 sm:mb-0 flex flex-col items-center justify-center h-full'>
            <div className='px-8 md:px-0 flex flex-col sm:items-center sm:text-center justify-start relative w-full'>
                <div className=' '>
                    <motion.h2
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                        viewport={{ once: true }}
                        className='font-title ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                    >
                        <FormattedMessage defaultMessage="Start accepting payments today" id="uEpU5J" />
                    </motion.h2>
                    <div className='mt-8'>
                        <Button type="link" href="https://repaya.freshdesk.com/support/tickets/new" isExternal size="default" className="text-lg relative" color="default">
                            <div className="absolute inset-0 dark:bg-neutral-900 -z-10" />
                            <FormattedMessage defaultMessage="Contact us" id="WnQ/AZ" />
                        </Button>
                        <Button size="default" type="link" href="/build" className="font-title font-extrabold text-lg" color="primary">
                            <FormattedMessage defaultMessage="Start building" id="iQYFHF" />
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    </FixedSlide>;
}
