import cn from 'classnames'
import { ReactNode, useMemo, useRef } from 'react'
import { Payment } from './types'
import { useSupportedCoins } from '../coin/hooks'
import { add } from 'date-fns'
import PaymentRowDummy from './PaymentRowDummy'
import { FormattedMessage } from 'react-intl'

interface Props {
}

interface PaymentsResponse {
    page: number
    total: number
    totalPages: number
    items: Payment[]
}

function Th({ children, className }: { children: ReactNode, className?: string }) {
    return (
        <th className={cn('py-6 px-3 first:pl-6 pb-4 first:rounded-tl-2xl last:pr-6 last:rounded-tr-2xl font-semibold dark:bg-neutral-800 dark:bg-opacity-40 bg-neutral-100 bg-opacity-100', className)}>
            {children}
        </th>
    )
}

export function useLatest<T>(state: T | null): T | null {
    const ref = useRef(state)

    if (state != null) {
        ref.current = state
    }

    return ref.current
}

export default function TransactionsDummy({
}: Props) {
    const { data: supportedCoins } = useSupportedCoins(false)
    const data: PaymentsResponse = useMemo(() => {
        const ts = Math.floor(Date.now() / 1000 / 3600) * 1000 * 3600
        return {
            items: [
                {
                    id: '1',
                    customerId: '7319',
                    productId: '3251',
                    productName: 'Product item 1',
                    sender: '0x0000000000000000000000000000000000000000',
                    receiver: '0x0000000000000000000000000000000000000000',
                    amount: '15.0',
                    paidAmount: '0',
                    status: 'pending',
                    coin: 'USDC',
                    created: new Date(ts).toISOString()
                },
                {
                    id: '2',
                    customerId: '5161',
                    productId: '723',
                    productName: 'Product item 2',
                    sender: '0x0000000000000000000000000000000000000000',
                    receiver: '0x0000000000000000000000000000000000000000',
                    amount: '0.0142',
                    paidAmount: '0.0142',
                    status: 'completed',
                    coin: 'ETH',
                    created: add(new Date(ts), { minutes: -10 }).toISOString()
                },
                {
                    id: '3',
                    customerId: '9654',
                    productId: '3963',
                    productName: 'Product item 3',
                    sender: '0x0000000000000000000000000000000000000000',
                    receiver: '0x0000000000000000000000000000000000000000',
                    amount: '10.0',
                    paidAmount: '10.0',
                    status: 'completed',
                    coin: 'USDT',
                    created: add(new Date(ts), { minutes: -90 }).toISOString()
                },
            ],
            page: 1,
            totalPages: 1,
            total: 0
        }
    }, [])

    const showProductColumn = useMemo(() => {
        return !data || !data.items.every(t => t.productId == '')
    }, [data])

    return (
        <div className="">
            <table className={cn('mt-0 text-neutral-900 dark:text-neutral-100 w-full border-collapse transactions-table', {})}>
                <thead>
                    <tr className='text-left'>
                        {/* <Th>ID</Th> */}
                        <Th className='hidden sm:table-cell w-1/4'>
                            <FormattedMessage defaultMessage="Date" id="P7PLVj" />
                        </Th>
                        <Th className='w-1/4 rounded-tl-2xl sm:rounded-none '>
                            <FormattedMessage defaultMessage="From" id="dM+p3/" />
                        </Th>
                        {showProductColumn && (
                            <Th className='w-1/4 hidden xs:table-cell'>
                                <FormattedMessage defaultMessage="Product" id="x/ZVlU" />
                            </Th>
                        )}
                        {/* <Th>Receiver</Th> */}
                        <Th className='w-1/4'>
                            <span className='hidden md:inline'>
                                <FormattedMessage defaultMessage="Amount / Paod" id="RL5run" />
                            </span>
                            <span className='inline md:hidden'>
                                <FormattedMessage defaultMessage="Amount" id="/0TOL5" />
                            </span>
                        </Th>
                        <Th className='w-0'>
                            <FormattedMessage defaultMessage="Status" id="tzMNF3" />
                        </Th>
                    </tr>
                </thead>
                <tbody className={cn('transition-opacity duration-200', {})}>
                    {data != null && data.items.map((payment, i) => (
                        <PaymentRowDummy
                            key={payment.id}
                            payment={payment}
                            showProductColumn={showProductColumn}
                            coin={supportedCoins != null ? supportedCoins[payment.coin] ?? null : null}
                            dateStr={
                                {
                                    [0]: "11:00 PM",
                                    [1]: "10:34 PM",
                                    [2]: "10:02 PM",
                                }[i]!
                            }
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}