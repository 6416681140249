import cn from 'classnames'
import { Props as PaymentFormDummyTypesProps } from 'payment-form/PaymentFormDummyTypes';
import { CancelablePromise } from '@repaya/commons/async2';
import { FormattedMessage } from 'react-intl';
import { useEvent } from 'common/hooks';
import { ReactNode } from 'react';

interface SecondDemoTypeOptionProps {
    type: PaymentFormDummyTypesProps['type']
    activeType: PaymentFormDummyTypesProps['type']
    children: ReactNode
    onChange: (type: PaymentFormDummyTypesProps['type']) => void
}

export function SecondDemoTypeOption({ type, children, activeType, onChange }: SecondDemoTypeOptionProps) {
    const handleClick = useEvent(() => onChange(type))
    return (
        <div
            className={cn('last:mr-0 mx-2 md:mx-4 lg:mx-0 whitespace-nowrap lg:mb-8 cursor-pointer dark:hover:text-neutral-200 transition-colors duration-200', {
                'dark:text-neutral-500': activeType !== type,
                'dark:text-neutral-50': activeType === type,
            })}
            onClick={handleClick}
        >{children}</div>
    )
}

export function DemoTypeSelect(props: {
    onSecondDemoTypeSelect: (type: PaymentFormDummyTypesProps['type']) => CancelablePromise<void>;
    secondDemoType: PaymentFormDummyTypesProps['type'];
}) {
    const { onSecondDemoTypeSelect: handleSecondDemoTypeSelect, secondDemoType } = props;

    return <div className='flex flex-row w-full items-stretch lg:text-right justify-center lg:justify-start lg:flex-col'>
        <SecondDemoTypeOption
            onChange={handleSecondDemoTypeSelect}
            type='invoice'
            activeType={secondDemoType}
        >
            <FormattedMessage defaultMessage="Invoice" id="Ecfndm" />
        </SecondDemoTypeOption>
        <SecondDemoTypeOption
            onChange={handleSecondDemoTypeSelect}
            type='donation'
            activeType={secondDemoType}
        >
            <FormattedMessage defaultMessage="Donation" id="ABZjGf" />
        </SecondDemoTypeOption>
        <SecondDemoTypeOption
            onChange={handleSecondDemoTypeSelect}
            type='store'
            activeType={secondDemoType}
        >
            <span className='hidden sm:inline'>
                <FormattedMessage defaultMessage="Online store" id="Rr2Mwi" />
            </span>
            <span className='inline sm:hidden'>
                <FormattedMessage defaultMessage="Store" id="RTOlSm" />
            </span>
        </SecondDemoTypeOption>
        <SecondDemoTypeOption
            onChange={handleSecondDemoTypeSelect}
            type='subscription'
            activeType={secondDemoType}
        >
            <FormattedMessage defaultMessage="Subscription" id="R/6nsx" />
        </SecondDemoTypeOption>
        <SecondDemoTypeOption
            onChange={handleSecondDemoTypeSelect}
            type='deposit'
            activeType={secondDemoType}
        >
            <FormattedMessage defaultMessage="Deposit" id="dIgBOz" />
        </SecondDemoTypeOption>
    </div>;
}
