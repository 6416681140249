import cn from 'classnames'
import { useEffectOnce, useEvent, useIsMounted } from 'common/hooks'
import { ReactNode, useEffect, useRef, useState } from 'react'

interface Props {
    clickedId?: number
    children?: ReactNode
    innerClassName?: string
    className?: string
}

export default function ClickEffect({
    clickedId,
    className,
    innerClassName,
    children
}: Props) {
    const [isClicked, setClicked] = useState(false)
    const isMounted = useIsMounted()
    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const handleClick = useEvent(() => {
        setClicked(true)
        if (timerRef.current !== null) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
            if (!isMounted()) return
            setClicked(false)
        }, 500)
    })

    useEffect(() => {
        if (clickedId != null) {
            handleClick()
        }
    }, [clickedId])

    return (
        <div
            onClick={handleClick}
            className={cn('relative cursor-pointer', {}, className)}
        >
            <div className={cn({
                'click-animation-inner': isClicked
            }, innerClassName)}>
                <div className={cn({
                    'click-animation-inner-2': isClicked
                }, innerClassName)}>
                    <div className={cn({
                        'click-animation-inner-3': isClicked
                    }, innerClassName)}>
                    </div>
                </div>
            </div>
            {children}
        </div>
    )
}