
import cn from 'classnames'
import LinesAnimation from 'ui/LinesAnimation'
import Footer from 'components/Footer'
import { ActionBlock } from './ActionBlock'
import { CodeBlock } from './CodeBlock'
import { TransactionsBlock } from './TransactionsBlock'
import { WalletsBlock } from './WalletsBlock'
import { CoinsBlock } from './CoinsBlock'
import { SecondDemoType } from './SecondDemoType'
import { TitleBlock } from './TitleBlock'
import { CookieNotice } from 'components/CookieNotice'


interface Props {

}

export default function Landing({

}: Props) {
    return (
        <>
            <div className='snap-y snap-mandatory overflow-y-auto' style={{ height: 'calc(100% - 74px)' }}>
                <LinesAnimation maxScroll={2000} />
                <TitleBlock />
                <SecondDemoType />
                <CoinsBlock />
                <WalletsBlock />
                <TransactionsBlock />
                <CodeBlock />
                <ActionBlock />
                <Footer />
                <CookieNotice />
            </div >
        </>
    )
}

