import PaymentFormDummy from 'payment-form/PaymentFormDummy';
import FixedSlide from 'ui/FixedSlide';
import Check from 'ui/check.svg';
import { Button } from 'ui/Button';
import { FormattedMessage } from 'react-intl';

export function TitleBlock() {
    return <FixedSlide>
        <div className='mt-32 mb-32 sm:mt-0 sm:mb-0 flex flex-col lg:flex-row items-center justify-center w-full h-full'>
            <div className='mb-8 sm:mb-0 text-center flex flex-col lg:block items-center lg:text-left lg:w-4/12 2xl:w-3/12  px-10 lg:px-0 lg:mr-28'>
                <h1 className='font-title inline-block ls7 text-4xl dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90 font-extrabold'>
                    <FormattedMessage defaultMessage="Web3 payment gateway" id="F1l6ji" />
                </h1>
                <div className='mt-5 sm:w-8/12 lg:w-full xl:w-9/12'>
                    <p className='dark:text-neutral-400 dark:text-opacity-90 dark:bg-neutral-900  dark:bg-opacity-90 text-lg'>
                        <FormattedMessage defaultMessage="Accept ethereum and tokens in your app or online store and receive payments directly into your wallet" id="adAsPx" />
                    </p>
                </div>
                <div className="flex flex-row items-center gap-6 mt-8 mb-12 lg:mb-0">
                    <Button type="link" href="/build" size="default" className="font-title font-extrabold text-lg" color="primary">
                        <div className="absolute inset-0 dark:bg-neutral-900 -z-10" />
                        <FormattedMessage defaultMessage="Start building" id="iQYFHF" />
                    </Button>

                    <div className="flex flex-row items-center gap-2 text-neutral-200 bg-neutral-900 rounded-2xl whitespace-nowrap">
                        <div className="rounded-2xl w-6 h-6 border border-green-600 flex flex-row items-center justify-center relative" style={{ top: -2 }}>
                            <Check className="w-6 fill-green-400 relative"  style={{top: 1 }}/>
                        </div>
                        <FormattedMessage defaultMessage="Non-custodial" id="z+DuVB" />
                    </div>

                    <div className="flex flex-row items-center gap-2 text-neutral-200 bg-neutral-900 rounded-2xl whitespace-nowrap">
                        <div className="rounded-2xl w-6 h-6 border border-green-600 flex flex-row items-center justify-center relative" style={{ top: -2 }}>
                            <Check className="w-6 fill-green-400 relative"  style={{top: 1 }} />
                        </div>
                        <FormattedMessage defaultMessage="Rest API" id="Ekcumj" />
                    </div>
                </div>
            </div>

            <PaymentFormDummy />

        </div>
    </FixedSlide>;
}
