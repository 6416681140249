import Image from 'next/image';
import { motion } from 'framer-motion';
import FixedSlide from 'ui/FixedSlide';
import CodeSnippet from 'components/carbon.png';
import { Button } from 'ui/Button';
import { FormattedMessage } from 'react-intl';

export function CodeBlock() {
    return <FixedSlide className="">
        <div className='absolute inset-0 dark:bg-neutral-900' />
        <div className='mb-32 sm:mb-0 flex flex-col items-center justify-center h-full'>
            <div className='px-8 md:px-0 flex flex-col lg:flex-row items-center justify-center relative w-full'>
                <motion.div
                    className='sm:w-9/12 md:w-7/12 lg:mb-0 lg:ml-20 lg:w-4/12 2xl:w-3/12 mb-16 md:mb-16'
                    initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .2 }}
                    viewport={{ once: true }}
                >
                    <Image alt="code snippet" className='rounded-2xl' src={CodeSnippet} placeholder='blur' />
                </motion.div>
                <div className='sm:w-9/12 md:w-7/12 lg:mb-0 lg:ml-28 lg:w-3/12'>
                    <motion.h2
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .6 }}
                        viewport={{ once: true }}
                        className='font-title ls7 text-4xl font-extrabold dark:text-neutral-200 dark:bg-neutral-900 dark:bg-opacity-90'
                    >
                        <FormattedMessage defaultMessage="Integrate seamlessly" id="sjWvg2" />
                    </motion.h2>
                    <motion.div
                        initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: .8 }}
                        viewport={{ once: true }}
                        className='mt-8 text-lg'>
                        <div>
                            <FormattedMessage
                                defaultMessage="{brand} is designed with ease of integration in mind, offering an extensively documented Rest API. Start accepting payments and managing your transactions with just a few lines of code." id="yAU66h"
                                values={{
                                    brand: <span className="font-title font-extrabold text-xl">
                                        <FormattedMessage defaultMessage="repaya" id="TsETZ3" />
                                    </span>
                                }} />
                        </div>
                        <Button type='link' isExternal href='https://docs.repaya.io/docs/getting-started' className='mt-8' color='default'>
                            <FormattedMessage defaultMessage="Read the documentation" id="pYWXi5" />
                        </Button>
                    </motion.div>
                </div>
            </div>
        </div>
    </FixedSlide>;
}
